import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from './config';

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate(); 
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${config.domainAPI}/api/blog`);
        const data = await response.json();
        const foundBlog = data.find(blog => blog.tb10_id === parseInt(id));
        setBlog(foundBlog);
      } catch (error) {
        console.error('Erro ao buscar blogs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [id]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!blog) {
    return <div>Blog não encontrado.</div>;
  }

  const containerStyles = {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  };

  const imageStyles = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: '20px',
  };

  const titleStyles = {
    fontSize: '2em',
    margin: '0 0 10px',
  };

  const textStyles = {
    fontSize: '1em',
    lineHeight: '1.6',
    color: '#333',
  };

  return (
    <div style={containerStyles}>
      {blog.tb10_img ? (
        <img
          src={blog.tb10_img}
          alt={blog.tb10_titulo}
          style={imageStyles}
        />
      ) : (
        <div style={{ ...imageStyles, backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ color: '#999' }}>Sem Imagem</span>
        </div>
      )}
      <h1 style={titleStyles}>{blog.tb10_titulo}</h1>
      <div style={textStyles} dangerouslySetInnerHTML={{ __html: blog.tb10_texto }} />
      <div style={textStyles} dangerouslySetInnerHTML={{ __html: blog.tb10_texto_2 }} />
    </div>
  );
};

export default BlogDetail;
