import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './TopNavbar.css';
import { useNavigate } from 'react-router-dom';
import config from './config';

const TopNavbar = ({ user, loading }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [hasPaymentsAccess, setHasPaymentsAccess] = useState(false);
  const [hasWalletAccess, setHasWalletAccess] = useState(false);

  useEffect(() => {
    if (user) {
      axios.get(`${config.domainAPI}/api/meus-pagamentos`)
        .then(response => {
          if (response.status === 200) {
            setHasPaymentsAccess(true);
          }
        })
        .catch(error => console.error('Failed to fetch payments access:', error));
      axios.get(`${config.domainAPI}/api/meus-projetos/saldo-detalhado`)
        .then(response => {
          const { saldoTotal, saldoPendente, saldoLiberado } = response.data;
          if (saldoTotal !== 0 || saldoPendente !== 0 || saldoLiberado !== 0) {
            setHasWalletAccess(true);
          }
        })
        .catch(error => console.error('Failed to fetch wallet access:', error));
    }
  }, [user]);

  const handleNavClick = (path) => {
    navigate(path);
    setExpanded(false);
  };

  const hasParticipations = user && user.tb01_participacoes && user.tb01_participacoes.split(',').filter(Boolean).length > 0;

  return (
    <Navbar className="custom-navbar" expand="lg" expanded={expanded} collapseOnSelect>
      <Container>
      <Navbar.Brand onClick={() => handleNavClick("/")}>
                <img
            src="https://www.topiainvest.com.br/img/logo_desk.png"
            alt="Topia Invest"
            style={{ width: '100px', height: '40px' }}
          />
      </Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded(expanded => !expanded)} aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon">
            <span></span>
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => handleNavClick("/criar-projeto")}>Criar Projeto</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("/projetos")}>Projetos</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("/contato")}>Contato</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("/sobre")}>Sobre Nós</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("/faq")}>FAQ</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("/blog")}>Blog</Nav.Link>
            {loading ? (
              <Nav.Link className="login-link"></Nav.Link>
            ) : user ? (
              <NavDropdown title={<span className="login-link"><FontAwesomeIcon icon={faUser} /> {user.tb01_nome}</span>} id="basic-nav-dropdown" className="login-link user-icon">
                <NavDropdown.Item onClick={() => handleNavClick("/conta")}>Minha Conta</NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleNavClick("/meus-projetos")}>Meus Projetos</NavDropdown.Item>
                {hasWalletAccess && ( // Exibe "Minha Carteira" se tiver saldo
                  <NavDropdown.Item onClick={() => handleNavClick("/saldo")}>Minha Carteira</NavDropdown.Item>
                )}
                {hasParticipations && (
                  <NavDropdown.Item onClick={() => handleNavClick("/participacoes")}>Participações</NavDropdown.Item>
                )}
                {hasPaymentsAccess && (
                  <NavDropdown.Item onClick={() => handleNavClick("/pagamentos")}>Pagamentos Feitos</NavDropdown.Item>
                )}
                {user.tb01_id_funcao === 2 && (
                  <NavDropdown.Item onClick={() => handleNavClick("/painel-admin")}>Painel Admin</NavDropdown.Item>
                )}
              </NavDropdown>
            ) : (
              <Nav.Link onClick={() => handleNavClick("/login")} className="login-link">
                <FontAwesomeIcon icon={faUser} className="user-icon" /> Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
 
export default TopNavbar;
