import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import config from './config';

const PaymentForm = () => {
  const navigate = useNavigate();    
  const location = useLocation();
  const { amount, cardholderEmail, description, projectName, projectImage } = location.state || {};
  const [cardForm, setCardForm] = useState(null);

  useEffect(() => {
    if (amount === undefined || cardholderEmail === undefined || description === undefined) {
      console.error('Parâmetros insuficientes para inicializar o formulário.');
      return;
    }
    if (cardForm) {
        cardForm.unmount();
      }
    const initializeCardForm = async () => {
      await loadMercadoPago();
      const mp = new window.MercadoPago('APP_USR-94b3e94d-5009-48d1-b591-fdc31980d184');

      const newCardForm = mp.cardForm({
        amount: amount.toString(),
        autoMount: true,        
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Número do cartão',
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/YY',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Código de segurança',
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Titular do cartão',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Banco emissor',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Parcelas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número do documento',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'E-mail',
            value: cardholderEmail,
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn('Form Mounted handling error: ', error);
            console.log('Form mounted');
          },
        onFormUnmounted: error => {
            if (error) return console.warn('Form Unmounted handling error: ', error);
            console.log('Form unmounted');
        },
        onIdentificationTypesReceived: (error, data) => {
            if (error) return console.warn('Identification Types Received handling error: ', error);
            console.log('Identification Types: ', data);
        },
        onPaymentMethodsReceived: (error, data) => {
            if (error) return console.warn('Payment Methods Received handling error: ', error);
            console.log('Payment Methods: ', data);
        },
        onIssuersReceived: (error, data) => {
            if (error) return console.warn('Issuers Received handling error: ', error);
            console.log('Issuers: ', data);
        },
        onInstallmentsReceived: (error, data) => {
            if (error) return console.warn('Installments Received handling error: ', error);
            console.log('Installments: ', data);
        },
        onCardTokenReceived: (error, data) => {
            if (error) {
            toast.error('Erro ao criar o token do cartão. Por favor, verifique os dados inseridos.');
            return console.warn('Card Token Received handling error: ', error);
            }
            console.log('Card Token: ', data);
        },
          onSubmit: async event => {
            event.preventDefault();

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = newCardForm.getCardFormData();

            try {
              if (!token) {
                toast.error('Erro ao criar o token do cartão. Por favor, verifique os dados inseridos.');
                return;
              }

              newCardForm.unmount();
              const response = await axios.post(`${config.domainAPI}/api/process_payment`, {
                token,
                issuer_id,
                payment_method_id,
                transaction_amount: amount,
                installments,
                description,
                payer: {
                  email,
                  identification: {
                    type: identificationType,
                    number: identificationNumber
                  }
                }
              });

              console.log('Payment successful:', response.data);
              navigate('/pagamentos');
            } catch (error) {
              console.error('Error processing payment:', error);
              if (error.response && error.response.data && error.response.data.cause) {
                error.response.data.cause.forEach(err => {
                  toast.error(`Erro: ${err.message} (${err.field})`);
                });
              } else {
                toast.error('Erro ao processar o pagamento. Por favor, tente novamente.');
                navigate('/projetos');
              }
            }
          },
          onFetching: (resource) => {
            console.log('Fetching resource: ', resource);
          }
        },
      });

      setCardForm(newCardForm);
    };

    initializeCardForm();

    return () => {
      if (cardForm) {
        cardForm.unmount();
      }
    };
  }, [amount, cardholderEmail, description]);

  return (
    <div className="payment-form-container">
      <style>{`
        .payment-form-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
        }

        .project-image {
          max-width: 100px;
          height: auto;
          border-radius: 8px;
          margin-bottom: 10px;
        }

        .project-title {
          font-size: 20px;
          color: #001326;
          margin-bottom: 5px;
        }

        .project-amount {
          font-size: 18px;
          color: #ffc85c;
          margin-bottom: 20px;
        }

        #form-checkout {
          width: 100%;
          max-width: 400px;
          display: flex;
          flex-direction: column;
          background: #fff;
          padding: 15px;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        input, select, button {
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }

        button {
          background-color: #001326;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .container1 {
          height: 40px;
          display: inline-block;
          border: 1px solid rgb(118, 118, 118);
          border-radius: 4px;
          padding: 8px;
          margin-bottom: 10px;
          font-size: 16px;
        }
        button:hover {
          background-color: #ffc85c;
          color: #001326;
        }
      `}</style>
      {projectImage && <img src={projectImage} alt="Project Image" className="project-image" />}
      {projectName && <div className="project-title">{projectName}</div>}
      {amount && <div className="project-amount">R$ {amount.toFixed(2)}</div>}
      <form id="form-checkout">
        <div id="form-checkout__cardNumber" className="container1"></div>
        <div id="form-checkout__expirationDate" className="container1"></div>
        <div id="form-checkout__securityCode" className="container1"></div>
        <input
  type="text"
  id="form-checkout__cardholderName"
  placeholder="Titular do cartão"
  style={{
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '100%',    
    fontSize: '16px'
  }}
/>
        <select id="form-checkout__issuer"></select>
        <select id="form-checkout__installments"></select>
        <select id="form-checkout__identificationType"></select>
        <input type="text" id="form-checkout__identificationNumber" placeholder="Número do documento"   style={{
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '100%',    
    fontSize: '16px'
  }}/>
        <input type="email" id="form-checkout__cardholderEmail" value={cardholderEmail} readOnly />
        <button type="submit" id="form-checkout__submit">Pagar</button>
      </form>
      <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div>
  );
};

export default PaymentForm;