import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Conta.css';
import config from './config';

const Conta = ({ user }) => {
    const navigate = useNavigate();
  
    const handleLogout = async () => {
        try {
            const logoutResponse = await fetch(`${config.domainAPI}/api/logout`, {
                method: 'POST',
                credentials: 'include',
            });
    
            if (logoutResponse.ok) {
                try {
                    await fetch(`${config.domainAPI}/api/user`, {
                        method: 'GET',
                        credentials: 'include',
                    });
                } catch (error) {
                    console.error('Erro ao verificar o usuário:', error);
                } finally {
                    window.location.href = '/login'; 
                }
            } else {
                console.error('Falha ao tentar fazer logout.');
            }
        } catch (error) {
            console.error('Erro ao fazer logout:', error);
        }
    };
 
  if (!user) {
    return <div className="conta-container"><p>Carregando dados do usuário...</p></div>;
  }
  return (
    <div className="conta-container">
      <div className="conta-card">
        <h2>Minha Conta</h2>
        <div className="conta-info">
          <div className="conta-info-row">
            <p><strong>Nome:</strong> {user.tb01_nome}</p>
            <p><strong>Sobrenome:</strong> {user.tb01_sobrenome}</p>
            <p><strong>Email:</strong> {user.tb01_email}</p>
            <p><strong>CPF:</strong> {user.tb01_cpf}</p>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>Sair da Conta</button>
      </div>
    </div>
  );
};

export default Conta;
