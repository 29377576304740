import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';

const FaqAccordion = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null); 

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/faq`, { withCredentials: true });
      setFaqs(response.data);
    } catch (error) {
      toast.error('Erro ao buscar FAQ');
    }
  };

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index); 
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Perguntas Frequentes</h1>
      {faqs.map((faq, index) => (
        <div key={faq.tb12_id} style={styles.faqContainer}>
          <div style={styles.questionBox} onClick={() => toggleFaq(index)}>
            <span style={styles.questionText}>{faq.tb12_pergunta}</span>
            <span style={styles.icon}>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {activeIndex === index && (
            <div style={styles.answerBox}>
              <div dangerouslySetInnerHTML={{ __html: faq.tb12_resposta }}></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: '#001326',
    marginBottom: '20px',
  },
  faqContainer: {
    marginBottom: '10px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  questionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#001326', 
    color: '#ffffff',
    padding: '15px',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'background-color 0.3s ease',
  },
  questionText: {
    fontSize: '1.2rem',
    flex: '1',
    textAlign: 'left',
  },
  icon: {
    marginLeft: '10px',
    fontSize: '1.2rem',
  },
  answerBox: {
    backgroundColor: '#f9f9f9',
    padding: '15px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    textAlign: 'left',
    animation: 'fadein 0.8s ease-in-out',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginTop: '10px',
  },
  '@keyframes fadein': {
    from: { opacity: 0, maxHeight: '0px' },
    to: { opacity: 1, maxHeight: '1000px' },
  },
};

export default FaqAccordion;
