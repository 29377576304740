import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import './Cadastro.css';
import confetti from 'canvas-confetti';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Termos from './Termos';
import Politica from './Politica'; 
import config from './config';

const steps = [
  { label: 'Email', type: 'email', validator: (value) => /^\S+@\S+\.\S+$/.test(value) },
  { label: 'CPF', type: 'text', validator: (value) => /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value) },
  { label: 'Nome', type: 'text', validator: (value) => /^[^\s]+$/.test(value) },
  { label: 'Data de Nascimento', type: 'text', validator: (value) => /^\d{2}\/\d{2}\/\d{4}$/.test(value) },
  { label: 'Telefone', type: 'tel', validator: (value) => /^\+55\s\(\d{2}\)\s\d{4,5}-\d{4}$/.test(value) },
  { label: 'Senha', type: 'password', validator: (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value) },
  { label: 'Repetir Senha', type: 'password', validator: (value, formData) => value === formData.senha },
];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    width: '400px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const customStyles2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    width: '90%',
    maxWidth: '800px', 
    maxHeight: '80vh', 
    overflowY: 'auto', 
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};
Modal.setAppElement('#root');
const Cadastro = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    cpf: '',
    nome: '',
    sobrenome: '',
    data_de_nascimento: '',
    telefone: '',
    senha: '',
    repetir_senha: '',
  });

  const [isModalTermsOpen, setIsModalTermsOpen] = useState(false);
  const [isModalPrivacyOpen, setIsModalPrivacyOpen] = useState(false);
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);

  const toggleModalTerms = () => setIsModalTermsOpen(!isModalTermsOpen);
  const toggleModalPrivacy = () => setIsModalPrivacyOpen(!isModalPrivacyOpen);
  const buttonStyle = {
    backgroundColor: isSubmitting ? '#cccccc' : '#FFC85C',
    cursor: isSubmitting ? 'not-allowed' : 'pointer',
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailForReset, setEmailForReset] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmailForReset('');
  };

  const handlePasswordReset = () => {
    if (!emailForReset) {
      toast.error('Por favor, insira um email válido.');
      return;
    }

    setIsSubmitting(true);
    axios.post(`${config.domainAPI}/api/forgot-password`, { email: emailForReset })
      .then(() => {
        toast.success('Um email foi enviado com instruções para redefinir sua senha.');
        closeModal();
      })
      .catch(() => {
        toast.error('Erro ao enviar o email de redefinição de senha.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const handleNext = async () => {
    const fieldName = steps[currentStep].label.toLowerCase().replace(/ /g, '_');
    const fieldValue = formData[fieldName];

    if (!steps[currentStep].validator(fieldValue, formData)) {
      toast.error(`Por favor, insira um ${steps[currentStep].label.toLowerCase()} válido.`);
      return;
    }

    if (steps[currentStep].label === 'Email') {
      try {
        const response = await axios.get(`${config.domainAPI}/api/verificar-email`, {
          params: { email: fieldValue },
        });
  
        if (response.data.exists) {
          toast.error('Este email já está registrado. Por favor, tente outro.');
          return; 
        }
      } catch (error) {
        toast.error('Erro ao verificar email. Tente novamente.');
        return; 
      }
    }

    if ((fieldName === 'nome' || fieldName === 'sobrenome') && fieldValue.includes(' ')) {
      toast.error(`${steps[currentStep].label} não pode conter espaços.`);
      return;
    }

    if (steps[currentStep].label === 'Data de Nascimento') {
      const [day, month, year] = fieldValue.split('/');
      const birthDate = new Date(`${year}-${month}-${day}`);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const isBeforeBirthday = today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());
  
      const calculatedAge = isBeforeBirthday ? age - 1 : age;
  
      if (calculatedAge < 18) {
        toast.error('Você deve ter pelo menos 18 anos para se cadastrar.');
        return;
      }
    }

    if (currentStep === steps.length - 1) {
      if (!agreeToTerms) {
        toast.error('Você deve concordar com os termos de uso.');
        return;
      }
      await handleRegister();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleNext();
    }
  };

  axios.defaults.withCredentials = true;

  const handleLogin = () => {
    const { email, senha } = formData;
    setIsSubmitting(true); 
    axios.post(`${config.domainAPI}/api/login`, { email: email, senha })
      .then(response => {
        toast.success('Login bem-sucedido');
        setTimeout(() => {
          const nextUrl = new URLSearchParams(location.search).get('next') || '/';
          window.location.href = nextUrl;
        }, 2000);
      })
      .catch(error => {
        toast.error('Erro ao fazer login. Verifique suas credenciais.');
      })
      .finally(() => {
        setIsSubmitting(false); 
      });
  };
  
  const handleRegister = async () => {
    const { nome, sobrenome, email, cpf, data_de_nascimento, telefone, senha } = formData;
    const usuario = {
      nome: nome,
      sobrenome: sobrenome,
      email,
      cpf,
      data_de_nascimento,
      senha,
      telefone
    };
    if (!agreeToTerms || !agreeToPrivacy) {
      toast.error('Você deve concordar com os Termos de Uso e a Política de Privacidade.');
      return;
    }
    setIsSubmitting(true); 
    axios.post(`${config.domainAPI}/api/register`, usuario)
      .then(response => {
        toast.success('Cadastro realizado com sucesso!');
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(error => {
        toast.error('Erro ao realizar o cadastro. Tente novamente.');
      })
      .finally(() => {
        setIsSubmitting(false); 
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };


  return (
    <div className="container-cadastro">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      {isLogin ? (
        <div className="login-step">
          <h2>Login</h2>
          <label className="form-label">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
          />
          <label className="form-label">Senha</label>
          <input
  type="password"
  name="senha"
  value={formData.senha}
  onChange={handleChange}
  onKeyPress={(e) => e.key === 'Enter' && handleLogin()} 
  className="form-input"
/>
<p onClick={openModal} className="link">
          Esqueci minha senha
        </p>
          <div className="button-group">
          <button 
  onClick={handleLogin} 
  className="button next" 
  style={buttonStyle} 
  disabled={isSubmitting}
>
  Entrar
</button>
          </div>
          <p onClick={() => setIsLogin(false)} className="link">
            Criar Conta
          </p>
      <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Esqueci minha senha"
  style={customStyles} 
>
  <h2>Esqueci Minha Senha</h2>
  <label htmlFor="email">Email</label>
  <input
    type="email"
    id="email"
    value={emailForReset}
    onChange={(e) => setEmailForReset(e.target.value)}
    className="form-input"
    placeholder="Digite seu email"
  />
  <div className="button-group">
    <button
      onClick={handlePasswordReset}
      className="button next"
      disabled={isSubmitting}
    >
      Enviar
    </button>
    <button onClick={closeModal} className="button prev">Cancelar</button>
  </div>
</Modal>
        </div>
      ) : (
        <>
          <h2>Cadastro</h2>
          {currentStep < steps.length ? (
            <div className="form-step">
              <label className="form-label">{steps[currentStep].label}</label>
              {steps[currentStep].label === 'Senha' && (
                <p className="password-instructions">
                  A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
                </p>
              )}
              <div className="input-wrapper">
                {steps[currentStep].label === 'Senha' ? (
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="senha"
                    value={formData.senha}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className="form-input"
                  />
                ) : steps[currentStep].label === 'Repetir Senha' ? (
                  <>
                    <input
                      type={showRepeatPassword ? 'text' : 'password'}
                      name="repetir_senha"
                      value={formData.repetir_senha}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      className="form-input"
                    />
        <div className="terms-container">
          <input
            type="checkbox"
            id="agreeToTerms"
            checked={agreeToTerms}
            onChange={() => setAgreeToTerms(!agreeToTerms)}
          />
          <label htmlFor="agreeToTerms" className="terms-label">
            Concordo com os{' '}
            <span onClick={toggleModalTerms} className="terms-link">
              Termos de Uso
            </span>
          </label>
        </div>

        <div className="privacy-container">
          <input
            type="checkbox"
            id="agreeToPrivacy"
            checked={agreeToPrivacy}
            onChange={() => setAgreeToPrivacy(!agreeToPrivacy)}
          />
          <label htmlFor="agreeToPrivacy" className="terms-label">
            Concordo com a{' '}
            <span onClick={toggleModalPrivacy} className="terms-link">
              Política de Privacidade
            </span>
          </label>
        </div>
                  </>
                ) : steps[currentStep].label === 'Data de Nascimento' ? (
                <InputMask
                  mask="99/99/9999"
                  maskChar="_"
                  type="text"
                  name="data_de_nascimento" 
                  value={formData.data_de_nascimento}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  className="form-input"
                  placeholder="dd/mm/aaaa"
                />
              ) : steps[currentStep].label === 'Nome' || steps[currentStep].label === 'Sobrenome' ? (
                <>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      name="nome"
                      value={formData.nome}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      className="form-input"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label className="form-label">Sobrenome</label>
                    <input
                      type="text"
                      name="sobrenome"
                      value={formData.sobrenome}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      className="form-input"
                    />
                  </div>
                </>
              ) : (
                  <InputMask
                    mask={steps[currentStep].label === 'CPF' ? '999.999.999-99' : steps[currentStep].label === 'Telefone' ? '+55 (99) 99999-9999' : ''}
                    maskChar=""
                    type={steps[currentStep].type}
                    name={steps[currentStep].label.toLowerCase().replace(/ /g, '_')}
                    value={formData[steps[currentStep].label.toLowerCase().replace(/ /g, '_')]}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className="form-input"
                  />
                )}
                {(steps[currentStep].label === 'Senha' || steps[currentStep].label === 'Repetir Senha') && (
                  <span
                    className="password-toggle-icon"
                    onClick={steps[currentStep].label === 'Senha' ? toggleShowPassword : toggleShowRepeatPassword}
                  >
                    {steps[currentStep].label === 'Senha' ? (
                      showPassword ? <FaEyeSlash /> : <FaEye />
                    ) : (
                      showRepeatPassword ? <FaEyeSlash /> : <FaEye />
                    )}
                  </span>
                )}
              </div>
              <div className="button-group">
                {currentStep > 0 && (
                  <button onClick={handlePrev} className="button prev">
                    Anterior
                  </button>
                )}

<button 
  onClick={handleNext} 
  className="button next" 
  style={buttonStyle} 
  disabled={isSubmitting}
>
  {currentStep === steps.length - 1 ? 'Cadastrar' : 'Próximo'}
</button>
              </div>
              <Modal
        isOpen={isModalTermsOpen}
        onRequestClose={toggleModalTerms}
        contentLabel="Termos de Uso"
        style={customStyles2}
      >
        <Termos />
        <button onClick={toggleModalTerms} className="button prev">Fechar</button>
      </Modal>

      <Modal
        isOpen={isModalPrivacyOpen}
        onRequestClose={toggleModalPrivacy}
        contentLabel="Política de Privacidade"
        style={customStyles2}
      >
        <Politica />
        <button onClick={toggleModalPrivacy} className="button prev">Fechar</button>
      </Modal>
    </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Cadastro;
