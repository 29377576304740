import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from './config';

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${config.domainAPI}/api/blog`);
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error('Erro ao buscar blogs:', error);
      }
    };

    fetchBlogs();
  }, []);
  const containerStyles = {
    padding: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
  };

  const cardStyles = {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '300px',
    transition: 'transform 0.2s ease',
  };

  const cardHoverStyles = {
    transform: 'scale(1.02)',
  };

  const imageStyles = {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  };

  const contentStyles = {
    padding: '10px',
  };

  const titleStyles = {
    fontSize: '1.2em',
    margin: '0 0 10px',
  };

  const textStyles = {
    fontSize: '0.9em',
    color: '#555',
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const handleCardClick = (id) => {
    navigate(`/blog/${id}`); 
  };

  return (
    <div style={containerStyles}>
      {blogs.map(blog => (
        <div
          key={blog.tb10_id}
          style={cardStyles}
          onClick={() => handleCardClick(blog.tb10_id)}
          onMouseOver={(e) => e.currentTarget.style.transform = cardHoverStyles.transform}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          {blog.tb10_img ? (
            <img
              src={blog.tb10_img}
              alt={blog.tb10_titulo}
              style={imageStyles}
            />
          ) : (
            <div style={{ ...imageStyles, backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span style={{ color: '#999' }}>Sem Imagem</span>
            </div>
          )}
          <div style={contentStyles}>
            <h3 style={titleStyles}>{blog.tb10_titulo}</h3>
            <p style={textStyles} dangerouslySetInnerHTML={{ __html: blog.tb10_texto.substring(0, 50) }} />

          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogSection;
