import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Paineladmin.css';
import { initializeApp } from 'firebase/app';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Modal from 'react-modal';
import config from './config';

// Importações para o Summernote
import $ from 'jquery';
import 'summernote/dist/summernote-lite.css';
import 'summernote/dist/summernote-lite.js';

const firebaseConfig = {
  apiKey: "AIzaSyB6YaK9zZw496-fIpJJWd0ljDz8gA3qLEY",
  authDomain: "topiainvest.firebaseapp.com",
  projectId: "topiainvest",
  storageBucket: "topiainvest.appspot.com",
  messagingSenderId: "620253874717",
  appId: "1:620253874717:web:5e7d759890de1436fa54f4",
  measurementId: "G-H11LC3M787"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const Paineladmin = () => {
  const [projetos, setProjetos] = useState([]);
  const [contatos, setContatos] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [activeTab, setActiveTab] = useState('projetos');
  const [newPost, setNewPost] = useState({ title: '', text: '', text_2: '', image: null });
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const [saques, setSaques] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [newFaq, setNewFaq] = useState({ question: '', answer: '' });
  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
  const faqEditorRef = useRef(null);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [isEditFaqModalOpen, setIsEditFaqModalOpen] = useState(false);
  const handleFaqEditClick = (faq) => {
    setSelectedFaq(faq);
    setIsEditFaqModalOpen(true);
  };
  useEffect(() => {
    if (activeTab === 'faq') {
      fetchFaqs();
    }
  }, [activeTab]);
  const createFaqEditorRef = useRef(null); 
  const editFaqEditorRef = useRef(null);
  useEffect(() => {
    if (isFaqModalOpen) {
      setTimeout(() => {
        $(createFaqEditorRef.current).summernote({
          placeholder: 'Digite a resposta',
          tabsize: 2,
          height: 200,
        });
      }, 0);
    } else if (createFaqEditorRef.current) {
      $(createFaqEditorRef.current).summernote('destroy');
    }
  }, [isFaqModalOpen]);
  const handleFaqUpdate = async () => {
    const answer = $(editFaqEditorRef.current).summernote('code'); 
    
    if (selectedFaq.tb12_pergunta.length > 50) {
      toast.error('A pergunta deve ter no máximo 50 caracteres.');
      return;
    }
  
    try {
      await axios.put(
        `${config.domainAPI}/api/faq/${selectedFaq.tb12_id}`,
        {
          pergunta: selectedFaq.tb12_pergunta,
          resposta: answer, 
        },
        { withCredentials: true }
      );
  
      toast.success('FAQ atualizada com sucesso!');
      fetchFaqs(); 
      setSelectedFaq(null);
      setIsEditFaqModalOpen(false);
    } catch (error) {
      toast.error('Erro ao atualizar FAQ');
    }
  };
  const fetchFaqs = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/faq`, { withCredentials: true });
      setFaqs(response.data);
    } catch (error) {
      toast.error('Erro ao buscar FAQ');
    }
  };
  useEffect(() => {
    if (isEditFaqModalOpen && selectedFaq) {
      setTimeout(() => {
        $(editFaqEditorRef.current).summernote({
          placeholder: 'Digite a resposta',
          tabsize: 2,
          height: 200,
        });
        $(editFaqEditorRef.current).summernote('code', selectedFaq.tb12_resposta); 
      }, 0);
    } else if (editFaqEditorRef.current) {
      $(editFaqEditorRef.current).summernote('destroy');
    }
  }, [isEditFaqModalOpen, selectedFaq]);
  const handleNewFaqSubmit = async () => {
    const answer = $(createFaqEditorRef.current).summernote('code'); 
  
    if (newFaq.question.length > 50) {
      toast.error('A pergunta deve ter no máximo 50 caracteres.');
      return;
    }
  
    if (!newFaq.question || !answer) {
      toast.error('Pergunta e resposta são obrigatórias');
      return;
    }
  
    try {
      await axios.post(
        `${config.domainAPI}/api/faq`,
        {
          pergunta: newFaq.question,
          resposta: answer, 
        },
        { withCredentials: true }
      );
  
      toast.success('FAQ adicionada com sucesso!');
      fetchFaqs();
      setNewFaq({ question: '', answer: '' });
      setIsFaqModalOpen(false);
    } catch (error) {
      toast.error('Erro ao adicionar FAQ');
    }
  };
  const createEditorRef = useRef(null);
  const editEditorRef = useRef(null);
  const createText2EditorRef = useRef(null);
  const editText2EditorRef = useRef(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchProjetos();
    await fetchContatos();
    await fetchBlogs();
    await fetchUsuarios();
    await fetchSaques();
  };

  const fetchUsuarios = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/usuarios`, { withCredentials: true });
      setUsuarios(response.data);
    } catch (error) {
      toast.error('Erro ao buscar usuários');
    }
  };

  const handleFuncaoToggle = async (id) => {
    try {
      await axios.put(`${config.domainAPI}/api/usuarios/${id}/alterar-funcao`, {}, { withCredentials: true });

      toast.success('Função alterada com sucesso!');
      fetchUsuarios();
    } catch (error) {
      toast.error('Erro ao alterar função');
    }
  };

  const fetchProjetos = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/projetos/admin`, { withCredentials: true });
      setProjetos(response.data);
    } catch (error) {
      handleError('Erro ao buscar projetos');
    }
  };

  const fetchContatos = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/contatos`, { withCredentials: true });
      setContatos(response.data);
    } catch (error) {
      handleError('Erro ao buscar contatos');
    }
  };

  const fetchSaques = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/saques/admin`, { withCredentials: true });
      setSaques(response.data);
    } catch (error) {
      toast.error('Erro ao buscar saques');
    }
  };

  const handleStatusChange = async (id, novoStatus) => {
    try {
      await axios.put(`${config.domainAPI}/api/saques/${id}/atualizar-status`, { status: novoStatus }, { withCredentials: true });
      toast.success('Status atualizado com sucesso!');
      fetchSaques();
    } catch (error) {
      toast.error('Erro ao atualizar status do saque');
    }
  };

  const UsuariosTable = ({ usuarios, handleFuncaoToggle }) => {
    const containerStyles = {
      padding: '10px',
      overflowX: 'auto',
    };

    const headerContainerStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    };

    const titleStyles = {
      fontSize: '1.5em',
      margin: '0',
    };

    const tableStyles = {
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
    };

    const headerStyles = {
      textAlign: 'left',
      padding: '10px',
      borderBottom: '1px solid #ddd',
    };

    const cellStyles = {
      padding: '10px',
      borderBottom: '1px solid #ddd',
    };

    const responsiveStyles = `
      @media (max-width: 768px) {
        table {
          font-size: 0.8em;
        }
        th, td {
          padding: 8px;
        }
      }
    `;

    return (
      <div style={containerStyles}>
        <div style={headerContainerStyles}>
          <h2 style={titleStyles}>Todos os Usuários</h2>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <table style={tableStyles}>
            <thead>
              <tr>
                <th style={headerStyles}>Nome</th>
                <th style={headerStyles}>Sobrenome</th>
                <th style={headerStyles}>Email</th>
                <th style={headerStyles}>CPF</th>
                <th style={headerStyles}>Data de Cadastro</th>
                <th style={headerStyles}>Admin</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((user) => {
                return (
                  <tr key={user.tb01_id} style={{ cursor: 'pointer', borderBottom: '1px solid #ddd' }}>
                    <td style={cellStyles}>{user.tb01_nome}</td>
                    <td style={cellStyles}>{user.tb01_sobrenome}</td>
                    <td style={cellStyles}>{user.tb01_email}</td>
                    <td style={cellStyles}>{user.tb01_cpf}</td>
                    <td style={cellStyles}>{new Date(user.tb01_dt_cad).toLocaleDateString()}</td>
                    <td style={cellStyles}>
                      <button
                        onClick={() => handleFuncaoToggle(user.tb01_id)}
                        style={{
                          padding: '5px 10px',
                          backgroundColor: user.tb01_id_funcao === 1 ? '#28a745' : '#dc3545',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                        }}
                      >
                        {user.tb01_id_funcao === 1 ? 'Ativar' : 'Remover'}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <style>
          {responsiveStyles}
        </style>
      </div>
    );
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${config.domainAPI}/api/blog`, { withCredentials: true });
      setBlogs(response.data);
    } catch (error) {
      handleError('Erro ao buscar posts do blog');
    }
  };

  const handleError = (message) => {
    console.error(message);
    toast.error(`${message}. Tente novamente.`);
  };

  const handleRowClick = (id, type) => {
    if (type === 'projeto') {
      navigate(`/projeto/admin/${id}`);
    } else if (type === 'contato') {
      const contato = contatos.find(c => c.tb09_email === id);
      setSelectedContact(contato);
    } else if (type === 'blog') {
      const blog = blogs.find(b => b.tb10_id === id);
      setSelectedBlog(blog);
      setIsEditModalOpen(true);
    }
  };

  const handleImageUpload = async (file) => {

    const storageReference = storageRef(storage, `images/${file.name}`);
    try {
      await uploadBytes(storageReference, file);
      const url = await getDownloadURL(storageReference);
      return url;
    } catch (error) {
      toast.error('Erro ao fazer upload da imagem');
      console.error('Erro ao fazer upload da imagem:', error);
      return null;
    }
  };

  const handleNewPostSubmit = async () => {
    try {
      const content = $(createEditorRef.current).summernote('code');
      const content2 = $(createText2EditorRef.current).summernote('code');
      const imageUrl = await handleImageUpload(newPost.image);
  
      if (!newPost.title || !content || !content2) {
        toast.error('Título, texto e texto 2 são obrigatórios');
        return;
      }
  
      if (!imageUrl) {
        toast.error('Erro ao fazer upload da imagem');
        return;
      }
  
      await axios.post(
        `${config.domainAPI}/api/blog`,
        {
          title: newPost.title,  
          text: content,
          text_2: content2,
          image: imageUrl,
        },
        { withCredentials: true }
      );
      toast.success('Post criado com sucesso!');
      fetchBlogs();
      setNewPost({ title: '', text: '', text_2: '', image: null });
      setIsModalOpen(false);
    } catch (error) {
      handleError('Erro ao criar post do blog');
    }
  };

  const handleBlogUpdate = async () => {
    try {
      const content = $(editEditorRef.current).summernote('code');
      const content2 = $(editText2EditorRef.current).summernote('code');
      const imageUrl = await handleImageUpload(selectedBlog.image);
  
      await axios.post(
        `${config.domainAPI}/api/blog/${selectedBlog.tb10_id}`,
        {
          title: selectedBlog.tb10_titulo,
          text: content,
          text_2: content2,
          image: imageUrl || selectedBlog.tb10_img,
        },
        { withCredentials: true }
      );
      toast.success('Post atualizado com sucesso!');
      fetchBlogs();
      setSelectedBlog(null);
      setIsEditModalOpen(false);
    } catch (error) {
      handleError('Erro ao atualizar post do blog');
    }
  };
  
  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        $(createEditorRef.current).summernote({
          placeholder: 'Texto 1',
          tabsize: 2,
          height: 200,
          callbacks: {
            onChange: function (contents) {
              setNewPost((prevPost) => ({
                ...prevPost,
                text: contents || prevPost.text,
              }));
            },
          },
        });
  
        $(createText2EditorRef.current).summernote({
          placeholder: 'Texto 2',
          tabsize: 2,
          height: 200,
          callbacks: {
            onChange: function (contents) {
              setNewPost((prevPost) => ({
                ...prevPost,
                text_2: contents || prevPost.text_2,
              }));
            },
          },
        });
      }, 0);
    } else {
      if (createEditorRef.current) {
        $(createEditorRef.current).summernote('destroy');
      }
      if (createText2EditorRef.current) {
        $(createText2EditorRef.current).summernote('destroy');
      }
    }
  }, [isModalOpen]);
  
  useEffect(() => {
    if (isEditModalOpen && selectedBlog) {
      setTimeout(() => {
        $(editEditorRef.current).summernote({
          placeholder: 'Texto',
          tabsize: 2,
          height: 400,
          callbacks: {
            onChange: function (contents) {
              setSelectedBlog((prevBlog) => ({
                ...prevBlog,
                tb10_texto: contents || prevBlog.tb10_texto,
              }));
            },
          },
        });
        $(editEditorRef.current).summernote('code', selectedBlog.tb10_texto);
      }, 0);
    } else {
      if (editEditorRef.current) {
        $(editEditorRef.current).summernote('destroy');
      }
    }
  }, [isEditModalOpen]);

  useEffect(() => {
    if (isEditModalOpen && selectedBlog) {
      setTimeout(() => {
        $(editText2EditorRef.current).summernote({
          placeholder: 'Texto 2',
          tabsize: 2,
          height: 400,
          callbacks: {
            onChange: function (contents) {
              setSelectedBlog((prevBlog) => ({
                ...prevBlog,
                tb10_texto_2: contents || prevBlog.tb10_texto_2,
              }));
            },
          },
        });
        $(editText2EditorRef.current).summernote('code', selectedBlog.tb10_texto_2);
      }, 0);
    } else {
      if (editText2EditorRef.current) {
        $(editText2EditorRef.current).summernote('destroy');
      }
    }
  }, [isEditModalOpen]);

  

  return (
    <div className="meus-projetos-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      <div className="tabs">
        <button className={`tab-button ${activeTab === 'projetos' ? 'active' : ''}`} onClick={() => setActiveTab('projetos')} style={tabButtonStyle(activeTab === 'projetos')}>
          Projetos
        </button>
        <button className={`tab-button ${activeTab === 'saques' ? 'active' : ''}`} onClick={() => setActiveTab('saques')} style={tabButtonStyle(activeTab === 'saques')}>
          Saques
        </button>
        <button className={`tab-button ${activeTab === 'usuarios' ? 'active' : ''}`} onClick={() => setActiveTab('usuarios')} style={tabButtonStyle(activeTab === 'usuarios')}>
          Usuários
        </button>        
        <button className={`tab-button ${activeTab === 'contatos' ? 'active' : ''}`} onClick={() => setActiveTab('contatos')} style={tabButtonStyle(activeTab === 'contatos')}>
          Contatos
        </button>
        <button className={`tab-button ${activeTab === 'blog' ? 'active' : ''}`} onClick={() => setActiveTab('blog')} style={tabButtonStyle(activeTab === 'blog')}>
          Blog
        </button>
        <button className={`tab-button ${activeTab === 'faq' ? 'active' : ''}`} onClick={() => setActiveTab('faq')} style={tabButtonStyle(activeTab === 'faq')}>
          FAQ
        </button>
      </div>

      {activeTab === 'usuarios' && <UsuariosTable usuarios={usuarios} handleFuncaoToggle={handleFuncaoToggle} />}
      {activeTab === 'saques' && (
        <div className="table-container">
          <h2>Lista de Saques</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Data do Saque</th>
                <th>Chave do Saque</th>
                <th>Valor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {saques.map((saque) => (
                <tr key={saque.tb06_id} className="table-row">
                  <td>{saque.nome}</td>
                  <td>{saque.sobrenome}</td>
                  <td>{new Date(saque.tb06_data).toLocaleDateString()}</td>
                  <td>{saque.tb06_descricao}</td>
                  <td>{saque.tb06_valor ? `R$ ${parseFloat(saque.tb06_valor).toFixed(2)}` : 'Valor inválido'}</td>
                  <td>
                    <select
                      value={saque.tb06_status_saque}
                      onChange={(e) => handleStatusChange(saque.tb06_id, e.target.value)}
                    >
                      <option value="PENDENTE">PENDENTE</option>
                      <option value="FEITO">FEITO</option>
                      <option value="CANCELADO">CANCELADO</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === 'projetos' && <ProjetosTable projetos={projetos} handleRowClick={handleRowClick} />}
      {activeTab === 'contatos' && <ContatosTable contatos={contatos} handleRowClick={handleRowClick} />}
      {activeTab === 'faq' && (
      <div>
        <h2>Gerenciar FAQ</h2>
        <button onClick={() => setIsFaqModalOpen(true)} style={modalButtonStyle}>Adicionar Nova Pergunta</button>

                  <table className="faq-table">
            <thead>
              <tr>
                <th>Pergunta</th>
                <th>Resposta</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {faqs.map(faq => (
                <tr key={faq.tb12_id}>
                  <td>{faq.tb12_pergunta}</td>
                  <td dangerouslySetInnerHTML={{ __html: faq.tb12_resposta }}></td>
                  <td>
                    <button onClick={() => handleFaqEditClick(faq)} style={modalButtonStyle}>Editar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    )}
    <Modal isOpen={isEditFaqModalOpen} onRequestClose={() => setIsEditFaqModalOpen(false)} style={modalStyles}>
  <h2>Editar Pergunta</h2>
  <input
    type="text"
    value={selectedFaq?.tb12_pergunta || ''}
    onChange={(e) => setSelectedFaq({ ...selectedFaq, tb12_pergunta: e.target.value })}
    maxLength={50}
    style={inputStyle}
  />
  <div ref={editFaqEditorRef}></div>
  <div style={modalButtonContainerStyle}>
    <button onClick={handleFaqUpdate} style={modalButtonStyle}>Atualizar FAQ</button>
    <button onClick={() => setIsEditFaqModalOpen(false)} style={modalButtonStyle}>Cancelar</button>
  </div>
</Modal>

        <Modal isOpen={isFaqModalOpen} onRequestClose={() => setIsFaqModalOpen(false)} style={modalStyles}>
      <h2>Adicionar Nova Pergunta</h2>
      <input
        type="text"
        placeholder="Digite a pergunta (máx 50 caracteres)"
        value={newFaq.question}
        onChange={(e) => setNewFaq({ ...newFaq, question: e.target.value })}
        maxLength={50}
        style={inputStyle}
      />
      <div ref={createFaqEditorRef}></div> 
      <div style={modalButtonContainerStyle}>
        <button onClick={handleNewFaqSubmit} style={modalButtonStyle}>Adicionar FAQ</button>
        <button onClick={() => setIsFaqModalOpen(false)} style={modalButtonStyle}>Cancelar</button>
      </div>
    </Modal>
      {activeTab === 'blog' && (
        <BlogSection
          blogs={blogs}
          setIsModalOpen={setIsModalOpen}
          handleRowClick={handleRowClick}
        />
      )}

      {selectedContact && <ContactDetails contato={selectedContact} onClose={() => setSelectedContact(null)} />}

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={modalStyles}>
      <h2>Criar Novo Post</h2>
<input
  type="text"
  placeholder="Título"
  value={newPost.title}
  onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}  
  style={inputStyle}
/>
<h3>Texto 1</h3>
<div ref={createEditorRef}></div>
<h3>Texto 2</h3>
<div ref={createText2EditorRef}></div>
<input type="file" onChange={(e) => setNewPost({ ...newPost, image: e.target.files[0] })} />
<div style={modalButtonContainerStyle}>
  <button onClick={handleNewPostSubmit} style={modalButtonStyle}>Criar Post</button>
  <button onClick={() => setIsModalOpen(false)} style={modalButtonStyle}>Cancelar</button>
</div>
      </Modal>

      <Modal isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} style={modalStyles}>
      <h2>Editar Post</h2>
<input
  type="text"
  value={selectedBlog?.tb10_titulo || ''}  
  onChange={(e) => {
    setSelectedBlog((prevBlog) => ({
      ...prevBlog,
      tb10_titulo: e.target.value,
    }));
  }}
  style={inputStyle}
/>
<h3>Texto 1</h3>
<div ref={editEditorRef}></div>
<h3>Texto 2</h3>
<div ref={editText2EditorRef}></div>
<input
  type="file"
  onChange={(e) => {
    const updatedBlog = { ...selectedBlog, image: e.target.files[0] };  
    setSelectedBlog(updatedBlog);  
  }}
/>
<div style={modalButtonContainerStyle}>
  <button onClick={handleBlogUpdate} style={modalButtonStyle}>Atualizar Post</button>
  <button onClick={() => setIsEditModalOpen(false)} style={modalButtonStyle}>Cancelar</button>
</div>
</Modal>
    </div>
  );
};

const ProjetosTable = ({ projetos, handleRowClick }) => (
  <div>
    <h2>Todos os Projetos</h2>
    <table className="projetos-table">
      <thead>
        <tr>
          <th>Imagem</th>
          <th>Nome</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {projetos.map(projeto => (
          <tr key={projeto.tb03_id_projeto} onClick={() => handleRowClick(projeto.tb03_id_projeto, 'projeto')} className="projeto-row">
            <td>
              <img src={projeto.tb03_imagem} alt={projeto.tb03_nome} className="projeto-imagem" />
            </td>
            <td>{projeto.tb03_nome}</td>
            <td style={{ color: statusColor[projeto.tb03_id_status] }}>{statusText[projeto.tb03_id_status]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ContatosTable = ({ contatos, handleRowClick }) => (
  <div>
    <h2>Todos os Contatos</h2>
    <table className="projetos-table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Email</th>
          <th>Assunto</th>
        </tr>
      </thead>
      <tbody>
        {contatos.map(contato => (
          <tr key={contato.tb09_email} onClick={() => handleRowClick(contato.tb09_email, 'contato')} className="projeto-row">
            <td>{contato.tb09_nome}</td>
            <td>{contato.tb09_email}</td>
            <td>{contato.tb09_assunto}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const BlogSection = ({ blogs, setIsModalOpen, handleRowClick }) => {
  const containerStyles = {
    padding: '10px',
    overflowX: 'auto',
  };

  const headerContainerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const titleStyles = {
    fontSize: '1.5em',
    margin: '0',
  };

  const buttonStyles = {
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '1em',
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: '0.9em',
  };

  const headerStyles = {
    textAlign: 'left',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  };

  const cellStyles = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    wordBreak: 'break-word',
  };

  const textStyles = {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const imgStyles = {
    maxWidth: '100px',
    maxHeight: '100px',
    width: 'auto',
    height: 'auto',
  };

  const responsiveStyles = `
    @media (max-width: 768px) {
      table {
        font-size: 0.8em;
      }
      th, td {
        padding: 8px;
      }
      .truncated-text {
        max-width: 100px;
      }
      .blog-imagem {
        max-width: 80px;
        max-height: 80px;
      }
    }
  `;

  return (
    <div style={containerStyles}>
      <div style={headerContainerStyles}>
        <h2 style={titleStyles}>Gerenciar Blog</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          style={buttonStyles}
        >
          Criar Novo Post
        </button>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <table style={tableStyles}>
          <thead>
            <tr>
              <th style={headerStyles}>Título</th>
              <th style={headerStyles}>Texto</th>
              <th style={headerStyles}>Imagem</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map(blog => (
              <tr
                key={blog.tb10_id}
                onClick={() => handleRowClick(blog.tb10_id, 'blog')}
                style={{
                  cursor: 'pointer',
                  borderBottom: '1px solid #ddd',
                }}
              >
                <td style={{ ...cellStyles, ...textStyles }}>
                  {blog.tb10_titulo}
                </td>
                <td
                style={{ ...cellStyles, ...textStyles }}
                dangerouslySetInnerHTML={{
                  __html: blog.tb10_texto.length > 20
                    ? `${blog.tb10_texto.substring(0, 20)}...`
                    : blog.tb10_texto,
                }}
              ></td>
                <td style={cellStyles}>
                  {blog.tb10_img && (
                    <img
                      src={blog.tb10_img}
                      alt={blog.tb10_titulo}
                      style={imgStyles}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ContactDetails = ({ contato, onClose }) => {
  if (!contato) return null;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Email copiado para a área de transferência!');
    }).catch((err) => {
      console.error('Erro ao copiar o email: ', err);
      toast.error('Erro ao copiar o email.');
    });
  };

  return (
    <div style={modalBackdropStyle}>
      <div style={modalContentStyle}>
        <h2>Detalhes do Contato</h2>
        <p><strong>Nome:</strong> {contato.tb09_nome}</p>
        <p>
          <strong>Email:</strong>
          <span
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            onClick={() => copyToClipboard(contato.tb09_email)}
          >
            {contato.tb09_email}
          </span>
        </p>
        <p><strong>Assunto:</strong> {contato.tb09_assunto}</p>
        <p><strong>Mensagem:</strong> {contato.tb09_mensagem}</p>
        <button onClick={onClose} style={closeButtonStyle}>Fechar</button>
      </div>
    </div>
  );
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    maxWidth: '800px',
    width: '90%',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
};

const modalButtonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
};

const modalButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ffc85c',
  color: '#001326',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontWeight: 'bold',
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '10px',
  borderRadius: '5px',
  border: '1px solid #ccc',
};

const textareaStyle = {
  width: '100%',
  padding: '10px',
  height: '100px',
  borderRadius: '5px',
  border: '1px solid #ccc',
};

const createButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#28a745',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontWeight: 'bold',
};

const modalBackdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
};

const modalContentStyle = {
  background: 'white',
  padding: '20px',
  borderRadius: '5px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  maxWidth: '500px',
  width: '90%',
  zIndex: 2,
};

const closeButtonStyle = {
  padding: '10px 20px',
  cursor: 'pointer',
};

const tabButtonStyle = (isActive) => ({
  cursor: 'pointer',
  padding: '10px 20px',
  margin: '0 5px',
  backgroundColor: isActive ? '#ffc85c' : 'transparent',
  border: 'none',
  borderBottom: `2px solid ${isActive ? '#ffc85c' : 'transparent'}`,
  color: isActive ? '#001326' : '#666',
});

const statusText = {
  1: 'Aguardando Aprovação',
  2: 'Aprovado',
  3: 'Rejeitado',
  4: 'Concluído',
};

const statusColor = {
  1: '#ffc85c',
  2: '#28a745',
  3: '#dc3545',
  4: '#17a2b8',
};

export default Paineladmin;
