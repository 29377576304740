import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Chat.css';
import { BeatLoader } from 'react-spinners';
import config from './config';

const Chat = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [userId, setUserId] = useState(null);
  const [limit, setLimit] = useState(50);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await axios.get(`${config.domainAPI}/api/projetos/participacoes`, { withCredentials: true });
        const projectData = response.data.find(proj => proj.tb03_id_projeto === parseInt(id));
        if (projectData) {
          setProject(projectData);
        } else {
          setError('Projeto não encontrado');
        }
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchMessages = async (limit) => {
      try {
        const response = await axios.get(`${config.domainAPI}api/mensagens/${id}?limite=${limit}`, { withCredentials: true });
        setMessages(response.data);
        if (response.data.length === limit) {
          setHasMoreMessages(true);
        } else {
          setHasMoreMessages(false);
        }
        setLoading(false);
        scrollToBottom();
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    const fetchUserId = async () => {
      try {
        const response = await axios.get(`${config.domainAPI}/api/user`, { withCredentials: true });
        setUserId(response.data.tb01_id);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProjectDetails();
    fetchMessages(limit);
    fetchUserId();

    const intervalId = setInterval(() => {
      fetchMessages(limit);
    }, 120000);

    return () => clearInterval(intervalId);
  }, [id, limit]);

  const handleLoadMoreMessages = () => {
    setLimit(limit + 50);
  };

  const handleSendMessage = async () => {
    if (!messageText.trim()) return;

    try {
      await axios.post(`${config.domainAPI}/api/mensagens`, {
        id_projeto: id,
        texto: messageText,
      }, { withCredentials: true });

      const response = await axios.get(`${config.domainAPI}api/mensagens/${id}?limite=${limit}`, { withCredentials: true });
      setMessages(response.data);
      setMessageText('');
      scrollToBottom();
    } catch (error) {
      setError(error.message);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (loading) return     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  <BeatLoader color="#001326" />
</div>;
  if (error) return <p>Error: {error}</p>;
  if (!project) return <p>Projeto não encontrado</p>;

  return (
    <div className="project-details-container">
      <h1 className="project-title">{project.tb03_nome}</h1>
      {hasMoreMessages && (
        <button className="load-more-button" onClick={handleLoadMoreMessages}>
          Carregar anteriores
        </button>
      )}
      <div className="messages-container">
        {messages.map(msg => (
          <div key={msg.tb08_id_mensagem} className={`message-item ${msg.tb08_id_usuario === userId ? 'my-message' : 'other-message'}`}>
            <span className="message-username">{msg.tb01_nome} {msg.tb01_sobrenome}:</span> {msg.tb08_texto}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="send-message-container">
        <textarea
          className="message-input"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Digite sua mensagem..."
        />
        <button className="send-message-button" onClick={handleSendMessage}>Enviar</button>
      </div>
    </div>
  );
};
 
export default Chat;
